import { createRouter, createWebHashHistory } from 'vue-router';
import VueJwtDecode from 'vue-jwt-decode'
import Login from './pages/Login.vue'
import routePermissionsMap from './routePermissionsMap';
const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/unauthorized',
        name: 'unauthorized',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/',
        name: 'dashboard',
        exact: true,
        component: () => import('.//components/homepage/index.vue'),
        meta: {
            breadcrumb: [{ parent: 'Dashboard', label: 'Vendite del Giorno' }],
        },
    },
    {
        path: '/demo',
        name: 'demo-dashboard',
        exact: true,
        component: () => import('.//components/TableDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Dashboard', label: 'Vendite del Giorno' }],
        },
    },
    {
        path: '/ticket/nuovo',
        name: 'nuovo-ticket',
        exact: true,
        component: () => import('.//components/ticket/new.vue'),
        meta: {
            breadcrumb: [{ parent: 'Ticket', label: 'Nuovo' }],
        },
    },

    {
        path: '/ticketlist',
        name: 'ticketlist',
        exact: true,
        component: () => import('.//components/GestioneTicket/TicketList.vue'),
        meta: {
            breadcrumb: [{ parent: 'Ticket', label: 'Nuovo' }],
        },
    },

    {
        path: '/emaildetail',
        name: 'emaildetail',
        exact: true,
        component: () => import('.//components/GestioneTicket/EmailDetail.vue'),
        meta: {
            breadcrumb: [{ parent: 'Ticket', label: 'Nuovo' }],
        },
    },
    {
        path: '/ticketdetail/:id',
        name: 'ticketdetail',
        exact: true,
        component: () => import('.//components/GestioneTicket/TicketDetail.vue'),
        meta: {
            breadcrumb: [{ parent: 'Ticket', label: 'Nuovo' }],
        },
    },

    {
        path: '/ticketform',
        name: 'ticketform',
        exact: true,
        component: () => import('.//components/GestioneTicket/TicketForm.vue'),
        meta: {
            breadcrumb: [{ parent: 'Ticket', label: 'Nuovo' }],
        },
    },
    {
        path: '/inserimentomissioni',
        name: 'inserimentomissioni',
        exact: true,
        component: () => import('.//components/missioni/InserimentoMissioni.vue'),
        meta: {
            breadcrumb: [{ parent: 'Ticket', label: 'Nuovo' }],
        },
    },
    {
        path: '/checkmatricole',
        name: 'checkmatricole',
        exact: true,
        component: () => import('.//components/checkmatricole/CheckMatricole.vue'),
        meta: {
            breadcrumb: [{ parent: 'Ticket', label: 'Nuovo' }],
        },
    },
    {
        path: '/richiestapermessieferie',
        name: 'richiestapermessieferie',
        exact: true,
        component: () => import('.//components/permessieferie/RichiestaPermessiEFerie.vue'),
        meta: {
            breadcrumb: [{ parent: 'Ticket', label: 'Nuovo' }],
        },
    },

    {
        path: '/approvezionepermessieferie',
        name: 'approvezionepermessieferie',
        exact: true,
        component: () => import('.//components/permessieferie/ApprovazionePermessiEFerie.vue'),
        meta: {
            breadcrumb: [{ parent: 'Ticket', label: 'Nuovo' }],
        },
    },

    {
        path: '/parcoauto',
        name: 'parcoauto',
        exact: true,
        component: () => import('.//components/missioni/ParcoAuto.vue'),
        meta: {
            breadcrumb: [{ parent: 'Ticket', label: 'Nuovo' }],
        },
    },
    {
        path: '/schedetratte',
        name: 'schedetratte',
        exact: true,
        component: () => import('.//components/missioni/SchedeTratte.vue'),
        meta: {
            breadcrumb: [{ parent: 'Ticket', label: 'Nuovo' }],
        },
    },
    {
        path: '/chat',
        name: 'chat',
        exact: true,
        component: () => import('.//components/chat/Chat.vue'),
        meta: {
            breadcrumb: [{ parent: 'Chat', label: 'Chat' }],
        },
        children: [
            {
                path: ':topicId',
                name: 'topic',
                component: () => import('.//components/chat/Topic.vue'),
            },
            {
                path: ':topicId/post/:postId',
                name: 'topicPost',
                component: () => import('.//components/chat/Topic.vue'),
            },
            {
                path: ':topicId/post/:postId/comment/:commentId',
                name: 'topicComment',
                component: () => import('.//components/chat/Topic.vue'),
            },
        ],
    },
    /*ho definito questa rotta per far si che gli utenti amministrativi entrino in questa dashboard. ho defitino questa regola in pages>loging.vue*/
    {
        path: '/amministrazione',
        name: 'amministrazione',
        exact: true,
        component: () => import('.//components/DashboardPerUtente/Amministrazione.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche', label: 'Venduto del Mese' }],
        },
    },

    {
        path: '/ufficioacquisti',
        name: 'ufficioacquisti',
        exact: true,
        component: () => import('.//components/DashboardPerUtente/UfficioAcquisti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche', label: 'Venduto del Mese' }],
        },
    },
    {
        path: '/statistiche',
        name: 'statistiche',
        exact: true,
        component: () => import('./components/Statistiche.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche', label: 'Venduto del Mese' }],
        },
    },

    {
        path: '/demo',
        name: 'demo',
        exact: true,
        component: () => import('./components/demo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche', label: 'Venduto del Mese' }],
        },
    },
    {
        path: '/vendutodelgiorno',
        name: 'vendutodelgiorno',
        exact: true,
        component: () => import('./components/VenditeDelGiorno.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche', label: 'Venduto del Giorno' }],
        },
    },
    {
        path: '/schedafornitore',
        name: 'schedafornitore',
        exact: true,
        component: () => import('./components/SchedaFornitore/SchedaFornitore.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche', label: 'Venduto del Giorno' }],
        },
    },

    {
        path: '/statistichedocumentiarca',
        name: 'statistichedocumentiarca',
        exact: true,
        component: () => import('./components/statistichedswebapp/ControlloPreventiviArca.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche', label: 'Venduto del Giorno' }],
        },
    },
    {
        path: '/vendutopercliente',
        name: 'vendutopercliente',
        exact: true,
        component: () => import('./components/clienti/VendutoPerCliente.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche', label: 'Venduto del Giorno' }],
        },
    },
    {
        path: '/vendutoperbrand',
        name: 'vendutoperbrand',
        exact: true,
        component: () => import('./components/clienti/VendutoPerBrand.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche', label: 'Venduto del Giorno' }],
        },
    },
    {
        path: '/invendutomagazzini',
        name: 'invendutomagazzini',
        exact: true,
        component: () => import('./components/magazzini/invendutoarticoli/invendutomagazzini.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche', label: 'Venduto del Giorno' }],
        },
    },
    {
        path: '/ordinievadibilimf',
        name: 'ordinievadibilimf',
        exact: true,
        component: () => import('./components/magazzini/dashboardmagazzini/martinafranca/ordinievadibilimf.vue'),
        meta: {
            breadcrumb: [{ parent: 'Magazzini', label: 'Ordini Evadibili' }],
        },
    },

    {
        path: '/valorizzazionimagazzino',
        name: 'valorizzazionimagazzino',
        exact: true,
        component: () => import('./components/magazzini/valorizzazioni/ValorizzazioneMagazzino.vue'),
        meta: {
            breadcrumb: [{ parent: 'Magazzini', label: 'Ordini Evadibili' }],
        },
    },
    {
        path: '/ordinievadibilimfGestore',
        name: 'ordinievadibilimfGestore',
        exact: true,
        component: () => import('./components/magazzini/dashboardmagazzini/martinafranca/ordinievadibiliMFGestore.vue'),
        meta: {
            breadcrumb: [{ parent: 'Magazzini', label: 'Ordini Evadibili' }],
        },
    },

    {
        path: '/listeapertemartina',
        name: 'listeapertemartina',
        exact: true,
        component: () => import('./components/magazzini/dashboardmagazzini/martinafranca/listeapertemartina.vue'),
        meta: {
            breadcrumb: [{ parent: 'Magazzini', label: 'Ordini Evadibili' }],
        },
    },
    {
        path: '/listeapertefuorifido',
        name: 'listeapertefuorifido',
        exact: true,
        component: () => import('./components/magazzini/dashboardmagazzini/martinafranca/ListeAperteFuoriFido.vue'),
        meta: {
            breadcrumb: [{ parent: 'Magazzini', label: 'Ordini Evadibili' }],
        },
    },
    {
        path: '/listeapertefotovoltaico',
        name: 'listeapertefotovoltaico',
        exact: true,
        component: () => import('./components/magazzini/dashboardmagazzini/martinafranca/listeapertefotovoltaico.vue'),
        meta: {
            breadcrumb: [{ parent: 'Magazzini', label: 'Ordini Evadibili' }],
        },
    },
    {
        path: '/listeapertecasarano',
        name: 'listeapertecasarano',
        exact: true,
        component: () => import('./components/magazzini/dashboardmagazzini/casarano/listeapertecasarano.vue'),
        meta: {
            breadcrumb: [{ parent: 'Magazzini', label: 'Ordini Evadibili' }],
        },
    },
    {
        path: '/listeaperteoria',
        name: 'listeaperteoria',
        exact: true,
        component: () => import('./components/magazzini/dashboardmagazzini/oria/listeaperteoria.vue'),
        meta: {
            breadcrumb: [{ parent: 'Magazzini', label: 'Ordini Evadibili' }],
        },
    },
    {
        path: '/listeapertemodugno',
        name: 'listeapertemodugno',
        exact: true,
        component: () => import('./components/magazzini/dashboardmagazzini/modugno/listeapertemodugno.vue'),
        meta: {
            breadcrumb: [{ parent: 'Magazzini', label: 'Ordini Evadibili' }],
        },
    },

    {
        path: '/listeapertelecce',
        name: 'listeapertelecce',
        exact: true,
        component: () => import('./components/magazzini/dashboardmagazzini/lecce/listeapertelecce.vue'),
        meta: {
            breadcrumb: [{ parent: 'Magazzini', label: 'Ordini Evadibili' }],
        },
    },
    {
        path: '/consegne',
        name: 'consegne',
        exact: true,
        component: () => import('./components/magazzini/corrieri/consegne.vue'),
        meta: {
            breadcrumb: [{ parent: 'Magazzini', label: 'Ordini Evadibili' }],
        },
    },
    {
        path: '/consegnealbancomartinafranca',
        name: 'consegnealbancomartinafranca',
        exact: true,
        component: () => import('./components/magazzini/banconisti/ConsegneAlBancoMF.vue'),
        meta: {
            breadcrumb: [{ parent: 'Magazzini', label: 'Ordini Evadibili' }],
        },
    },


    {
        path: '/corrieri',
        name: 'corrieri',
        exact: true,
        component: () => import('./components/magazzini/corrieri/corrieri.vue'),
        meta: {
            breadcrumb: [{ parent: 'Magazzini', label: 'Ordini Evadibili' }],
        },
    },
    {
        path: '/lavoratodelgiornomf',
        name: 'lavoratodelgiornomf',
        exact: true,
        component: () => import('./components/magazzini/dashboardmagazzini/martinafranca/lavoratodelgiornoMF.vue'),
        meta: {
            breadcrumb: [{ parent: 'Magazzini', label: 'Ordini Evadibili' }],
        },
    },
    {
        path: '/confrontofatturati',
        name: 'confrontofatturati',
        exact: true,
        component: () => import('./components/clienti/ConfrontoFatturati.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche', label: 'Confronto Fatturati' }],
        },
    },
    {
        path: '/vendutoanto',
        name: 'vendutoanto',
        exact: true,
        component: () => import('./components/clienti/VendutoAnto.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche', label: 'Venduto del Giorno' }],
        },
    },
    {
        path: '/vendutodelmese',
        name: 'vendutodelmese',
        exact: true,
        component: () => import('./components/VenditeDelMese.vue'),
        meta: {
            breadcrumb: [{ parent: 'statistiche', label: 'Statistiche' }],
        },
    },
    {
        path: '/direzione',
        name: 'direzione',
        exact: true,
        component: () => import('.//components/DashboardPerUtente/Direzione.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche', label: 'Dashboard Direzionale' }],
        },
    },
    {
        path: '/giulioromanelli',
        name: 'giulioromanelli',
        exact: true,
        component: () => import('.//components/DashboardPerUtente/GiulioRomanelli.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche', label: 'Dashboard Direzionale' }],
        },

    },
    {
        path: '/fabrizioazzinnari',
        name: 'fabrizioazzinnari',
        exact: true,
        component: () => import('.//components/DashboardPerUtente/FabrizioAzzinnari.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche', label: 'Dashboard Direzionale' }],
        },

    },
    {
        path: '/pasqualezizzi',
        name: 'pasqualezizzi',
        exact: true,
        component: () => import('.//components/DashboardPerUtente/PasqualeZizzi.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche', label: 'Dashboard Direzionale' }],
        },

    },
    {
        path: '/dashboardvendutodelgiorno',
        name: 'dashboarddirezo',
        exact: true,
        component: () => import('./components/DashboardPerUtente/DashboardVendutoDelGiorno.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche', label: 'Dashboard Direzionale' }],
        },
    },
    {
        path: '/dashboardvenduto4anni',
        name: 'dashboardvenduto4anni',
        exact: true,
        component: () => import('./components/DashboardPerUtente/DashboardVenduto4Anni.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche', label: 'Dashboard Direzionale' }],
        },
    },
    {
        path: '/dettagliodoc',
        name: 'dettagliodoc',
        exact: true,
        component: () => import('./components/DettaglioDoc.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche', label: 'Venduto del Giorno' }],
        },
    },
    /*COMPONENT FORNITORI*/

    {
        path: '/acquisti',
        name: 'acquisti',
        exact: true,
        component: () => import('@/components/fornitori/Acquisti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Fornitori', label: 'Acquisti' }],
        },
    },
    {
        path: '/inevasofornitore',
        name: 'inevasofornitore',
        exact: true,
        component: () => import('.//components/fornitori/OrdiniInevasiFornitori.vue'),
        meta: {
            breadcrumb: [{ parent: 'Fornitori', label: 'Inevaso Fornitore' }],
        },
    },
    {
        path: '/importpromo',
        name: 'importpromo',
        exact: true,
        component: () => import('.//components/ManutenzioneListini/ImportPromo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Fornitori', label: 'Inevaso Fornitore' }],
        },
    },
    {
        path: '/indexincassi',
        name: 'indexincassi',
        exact: true,
        component: () => import('.//components/moduloincassi/IndexIncassi.vue'),
        meta: {
            breadcrumb: [{ parent: 'Fornitori', label: 'Inevaso Fornitore' }],
        },
    }, {
        path: '/sospesiperagente',
        name: 'sospesiperagente',
        exact: true,
        component: () => import('.//components/amministrazione/sospesi/SospesiPerAgente.vue'),
        meta: {
            breadcrumb: [{ parent: 'Fornitori', label: 'Inevaso Fornitore' }],
        },
    },
    {
        path: '/creascadenzaricorrente',
        name: 'creascadenzaricorrente',
        exact: true,
        component: () => import('.//components/amministrazione/pagamentiricorrenti/CreaScadenzaRicorrente.vue'),
        meta: {
            breadcrumb: [{ parent: 'Fornitori', label: 'Inevaso Fornitore' }],
        },
    },
    {
        path: '/pagamentiricorrenti',
        name: 'pagamentiricorrenti',
        exact: true,
        component: () => import('.//components/amministrazione/pagamentiricorrenti/PagamentiRicorrenti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Fornitori', label: 'Inevaso Fornitore' }],
        },
    },
    {
        path: '/matchscadenzeexcel',
        name: 'matchscadenzeexcel',
        exact: true,
        component: () => import('.//components/amministrazione/scadenze/MatchScadenzeExcel.vue'),
        meta: {
            breadcrumb: [{ parent: 'Fornitori', label: 'Inevaso Fornitore' }],
        },
    },
    {
        path: '/incassi/:Cd_CF',
        name: 'incassi',
        exact: true,
        //component: () => import('.//components/moduloincassi/DaCrm/Step2.vue'),
        component: () => import('.//components/moduloincassi/Step2.vue'),
        meta: {
            breadcrumb: [{ parent: 'Fornitori', label: 'Inevaso Fornitore' }],
        },
    },
    {
        path: '/articoli/:Cd_AR*',
        name: 'articoli',
        exact: true,
        //component: () => import('.//components/moduloincassi/DaCrm/Step2.vue'),
        component: () => import('.//components/articoli/ArticoliDaDSWeb.vue'),
        meta: {
            breadcrumb: [{ parent: 'Fornitori', label: 'Inevaso Fornitore' }],
        },
    },


    {
        path: '/incassibanconista',
        name: 'incassibanconista',
        exact: true,
        component: () => import('.//components/moduloincassi/banco/IndexIncassiBanconista.vue'),
        meta: {
            breadcrumb: [{ parent: 'Fornitori', label: 'Inevaso Fornitore' }],
        },
    },
    {
        path: '/manutenzioneincassi',
        name: 'manutenzioneincassi',
        exact: true,
        component: () => import('.//components/moduloincassi/ManutenzioneIncassi.vue'),
        meta: {
            breadcrumb: [{ parent: 'Incassi', label: 'ManutenzioneIncassi' }],
        },
    },
    {
        path: '/manutenzioneacconti',
        name: 'manutenzioneacconti',
        exact: true,
        component: () => import('.//components/moduloincassi/ManutenzioneAcconti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Incassi', label: 'ManutenzioneAcconti' }],
        },
    },
    {
        path: '/newincassi',
        name: 'newincassi',
        exact: true,
        component: () => import('.//components/moduloincassi/IndexIncassi.vue'),
        meta: {
            breadcrumb: [{ parent: 'Fornitori', label: 'Inevaso Fornitore' }],
        },
    },
    {
        path: '/portafoglioagenti',
        name: 'portafoglioagenti',
        exact: true,
        component: () => import('.//components/moduloincassi/portafoglioagenti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Fornitori', label: 'Inevaso Fornitore' }],
        },
    },

    {
        path: '/portafoglioagentibanco',
        name: 'portafoglioagentibanco',
        exact: true,
        component: () => import('.//components/moduloincassi/banco/portafoglioagentibanco.vue'),
        meta: {
            breadcrumb: [{ parent: 'Fornitori', label: 'Inevaso Fornitore' }],
        },
    },
    {
        path: '/portafoglioaccontiagenti',
        name: 'portafoglioaccontiagenti',
        exact: true,
        component: () => import('.//components/moduloincassi/portafoglioaccontiagenti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Fornitori', label: 'Portafoglio Acconti Agenti' }],
        },
    },
    {
        path: '/riassortimentoscorte',
        name: 'riassortimentoscorte',
        exact: true,
        component: () => import('.//components/fornitori/RiassortimentoScorte.vue'),
        meta: {
            breadcrumb: [{ parent: 'Fornitore', label: 'Riassortimento Scorte' }],
        },
    },
    {
        path: '/testlogin',
        name: 'testlogin',
        exact: true,
        component: () => import('.//components/TestLogin.vue'),
        meta: {
            breadcrumb: [{ parent: 'Fornitore', label: 'Riassortimento Scorte' }],
        },
    },
    {
        path: '/vendutoprodottiannocorrente',
        name: 'vendutoprodottiannocorrente',
        exact: true,
        component: () => import('.//components/Statistiche Venduto/VendutoProdottiAnnoCorrente.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Venduto Prodotti AnnoCorrente' }],
        },
    },
    {
        path: '/top50clientiperbrand',
        name: 'top50clientiperbrand',
        exact: true,
        component: () => import('.//components/Statistiche Venduto/Top50ClientiPerBrand.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Venduto Prodotti AnnoCorrente' }],
        },
    },

    {
        path: '/vendutoagentimensile',
        name: 'vendutoagentimensile',
        exact: true,
        component: () => import('.//components/Statistiche Venduto/VendutoAgentiMensile.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Venduto Prodotti AnnoCorrente' }],
        },
    },

    {
        path: '/ordiniinevasiarticolidsweb',
        name: 'ordiniinevasiarticolidsweb',
        exact: true,
        component: () => import('.//components/articoli/OrdiniInevasiArticoliDSWEB.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Venduto Prodotti AnnoCorrente' }],
        },
    },
    {
        path: '/ordiniinevasiclienti',
        name: 'ordiniinevasiclienti',
        exact: true,
        component: () => import('.//components/Statistiche Venduto/OrdiniInevasiClienti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Ordini Inevasi Clienti' }],
        },
    },

    {
        path: '/storicovendutofatturatoagenti',
        name: 'storicovendutofatturatoagenti',
        exact: true,
        component: () => import('.//components/Statistiche Venduto/StoricoVendutoFatturatoAgenti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Ordini Inevasi Clienti' }],
        },
    },

    {
        path: '/ordininonevasifiliali',
        name: 'ordininonevasifiliali',
        exact: true,
        component: () => import('.//components/Statistiche Venduto/OrdiniNonevasiClientiPerDoc.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Ordini Inevasi Clienti' }],
        },
    },

    {
        path: '/ordiniinevasimodugno',
        name: 'ordiniinevasimodugno',
        exact: true,
        component: () => import('.//components/magazzini/ordiniinevasi/ordiniinevasimodugno.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Ordini Inevasi Clienti' }],
        },
    },
    {
        path: '/ordiniinevasilecce',
        name: 'ordiniinevasilecce',
        exact: true,
        component: () => import('.//components/magazzini/ordiniinevasi/ordiniinevasilecce.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Ordini Inevasi Clienti' }],
        },
    },
    {
        path: '/ordiniinevasioria',
        name: 'ordiniinevasioria',
        exact: true,
        component: () => import('.//components/magazzini/ordiniinevasi/ordiniinevasioria.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Ordini Inevasi Clienti' }],
        },
    },
    {
        path: '/ordiniinevasicasarano',
        name: 'ordiniinevasicasarano',
        exact: true,
        component: () => import('.//components/magazzini/ordiniinevasi/ordiniinevasicasarano.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Ordini Inevasi Clienti' }],
        },
    },
    {
        path: '/ordiniinevasifiliali',
        name: 'ordiniinevasifiliali',
        exact: true,
        component: () => import('.//components/Statistiche Venduto/OrdiniInevasiClientiPerDoc.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Ordini Inevasi Clienti' }],
        },
    },


    {
        path: '/vendutoconincidenza',
        name: 'vendutoconincidenza',
        exact: true,
        component: () => import('.//components/Statistiche Venduto/VendutoConIncidenza.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Venduto Prodotti AnnoCorrente' }],
        },
    },

    {
        path: '/fatturatoperclienti',
        name: 'fatturatoperclienti',
        exact: true,
        component: () => import('.//components/Statistiche Venduto/FatturatoPerClienti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Venduto Prodotti AnnoCorrente' }],
        },
    },

    {
        path: '/fatturatoperclientifotovoltaico',
        name: 'fatturatoperclientifotovoltaico',
        exact: true,
        component: () => import('.//components/Statistiche Venduto/FatturatoPerClientiFotovoltaico.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Venduto Prodotti AnnoCorrente' }],
        },
    },
    {
        path: '/confrontoagentebrand',
        name: 'confrontoagentebrand',
        exact: true,
        component: () => import('.//components/Statistiche Venduto/ConfrontoAgenteBrand.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Venduto Prodotti AnnoCorrente' }],
        },
    },
    {
        path: '/clienti',
        name: 'clienti',
        exact: true,
        component: () => import('.//components/anagrafiche/Clienti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Venduto Prodotti AnnoCorrente' }],
        },
    },
    {
        path: '/inseriscianagraficheclienti',
        name: 'inseriscianagraficheclienti',
        exact: true,
        component: () => import('.//components/anagrafiche/InserimentoAnagraficheClienti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Venduto Prodotti AnnoCorrente' }],
        },
    },
    {
        path: '/fornitori',
        name: 'fornitori',
        exact: true,
        component: () => import('.//components/anagrafiche/Fornitori.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Venduto Prodotti AnnoCorrente' }],
        },
    },
    {
        path: '/anagrafiche',
        name: 'anagrafiche',
        exact: true,
        component: () => import('.//components/anagrafiche/Anagrafiche.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Venduto Prodotti AnnoCorrente' }],
        },
    },
    {
        path: '/articolids',
        name: 'articolids',
        exact: true,
        component: () => import('.//components/articoli/Articoli.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Venduto Prodotti AnnoCorrente' }],
        },
    },
    {
        path: '/richiestediarticolidadsweb',
        name: 'richiestediarticolidadsweb',
        exact: true,
        component: () => import('.//components/articoli/RichiesteDiArticoliDaDsWeb.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Venduto Prodotti AnnoCorrente' }],
        },
    },
    {
        path: '/confrontoagentefotovoltaico',
        name: 'confrontoagentefotovoltaico',
        exact: true,
        component: () => import('.//components/Statistiche Venduto/ConfrontoAgenteFotovoltaico.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Venduto Prodotti AnnoCorrente' }],
        },
    },

    {
        path: '/fatturatomensile',
        name: 'fatturatomensile',
        exact: true,
        component: () => import('.//components/Statistiche Venduto/FatturatoAgentiMensile.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Venduto Prodotti AnnoCorrente' }],
        },
    },
    {
        path: '/fatturatomensilemesiscorsi',
        name: 'fatturatomensilemesiscorsi',
        exact: true,
        component: () => import('.//components/Statistiche Venduto/StoricoFatturatoAgenti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Venduto Prodotti AnnoCorrente' }],
        },
    },
    {
        path: '/fatturatopivot',
        name: 'fatturatopivot',
        exact: true,
        component: () => import('.//components/Statistiche Venduto/FatturatoPivot.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Venduto Prodotti AnnoCorrente' }],
        },
    },
    {
        path: '/fatturatomensilefotovoltaico',
        name: 'fatturatomensilefotovoltaico',
        exact: true,
        component: () => import('.//components/Statistiche Venduto/FatturatoAgentiMensileFotovoltaico.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistiche Venduto', label: 'Venduto Prodotti AnnoCorrente' }],
        },
    },
    /*COMPONENT MANUTENZIONI*/
    {
        path: '/gestionepermessi',
        name: 'gestionepermessi',
        exact: true,
        component: () => import('.//components/GestionePermessi.vue'),
        meta: {
            breadcrumb: [{ parent: 'Manutenzioni', label: 'Gestione dei permessi' }],
        },
    },

    /*COMPONENT CLIENTI*/
    {
        path: '/inevasoclienti',
        name: 'inevasoclienti',
        exact: true,
        component: () => import('.//components/clienti/OrdiniInevasiCliente.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Ordini Inevasi Cliente' }],
        },
    },
    {
        path: '/presaincaricoriparazione',
        name: 'presaincaricoriparazione',
        exact: true,
        component: () => import('.//components/riparazioni/presaincarico.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Ordini Inevasi Cliente' }],
        },
    },
    {
        path: '/assegnazionefornitoremarche',
        name: 'assegnazionefornitoremarche',
        exact: true,
        component: () => import('.//components/riparazioni/assegnazionefornitoremarche.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Ordini Inevasi Cliente' }],
        },
    },

    {
        path: '/riparazioni',
        name: 'riparazioni',
        exact: true,
        component: () => import('.//components/riparazioni/riparazioni.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Ordini Inevasi Cliente' }],
        },
    },
    {
        path: '/statisticheriparazioni',
        name: 'statisticheriparazioni',
        exact: true,
        component: () => import('.//components/riparazioni/StatisticheRiparazioni.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Ordini Inevasi Cliente' }],
        },
    },
    {
        path: '/flowriparazioni',
        name: 'flowriparazioni',
        exact: true,
        component: () => import('.//components/riparazioni/flowriparazioni.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Ordini Inevasi Cliente' }],
        },
    },

    {
        path: '/situazionefornitori',
        name: 'situazionefornitori',
        exact: true,
        component: () => import('.//components/riparazioni/situazionefornitori.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Ordini Inevasi Cliente' }],
        },
    },
    {
        path: '/tabledemo',
        name: 'tabledemo',
        exact: true,
        component: () => import('.//components/TableDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Ordini Inevasi Cliente' }],
        },
    },

    {
        path: '/budgetcategorie',
        name: 'budgetcategorie',
        exact: true,
        component: () => import('.//components/budget/budgetcategorie.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'budgetcategorie' }],
        },
    },
    {
        path: '/entrate',
        name: 'entrate',
        exact: true,
        component: () => import('.//components/budget/entrate.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Ordini Inevasi Cliente' }],
        },
    },

    {
        path: '/uscite',
        name: 'uscite',
        exact: true,
        component: () => import('.//components/budget/uscite.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Ordini Inevasi Cliente' }],
        },
    },
    {
        path: '/documentaleriparazioni',
        name: 'documentaleriparazioni',
        exact: true,
        component: () => import('.//components/documentale/altro/documentaleriparazioni.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Sospesi Clienti' }],
        },
    },
    {
        path: '/storicovendutoprodotti',
        name: 'storicovendutoprodotti',
        exact: true,
        component: () => import('.//components/anagrafiche/SchedaVendutoProdotti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Sospesi Clienti' }],
        },
    },

    {
        path: '/sacchettoclienti',
        name: 'sacchettoclienti',
        exact: true,
        component: () => import('.//components/anagrafiche/SacchettoClienti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Sospesi Clienti' }],
        },
    },
    {
        path: '/sacchettoallclienti',
        name: 'sacchettoallclienti',
        exact: true,
        component: () => import('.//components/anagrafiche/SacchettoAllClienti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Sospesi Clienti' }],
        },
    },
    {
        path: '/statisticheacquistivendite',
        name: 'statisticheacquistivendite',
        exact: true,
        component: () => import('./components/modulostatistiche/StatisticheAcquistiVendite.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Sospesi Clienti' }],
        },
    },
    {
        path: '/sospesiclienti',
        name: 'sospesiclienti',
        exact: true,
        component: () => import('.//components/clienti/SospesiClienti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Sospesi Clienti' }],
        },
    },

    {
        path: '/anticipofatture',
        name: 'anticipofatture',
        exact: true,
        component: () => import('.//components/utility/anticipifatture/AnticipoFatture.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Sospesi Clienti' }],
        },
    },

    {
        path: '/statorobottino',
        name: 'statorobottino',
        exact: true,
        component: () => import('.//components/utility/StatoRobottino.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Sospesi Clienti' }],
        },
    },
    {
        path: '/sospesiclientidaricontattare',
        name: 'sospesiclientidariconttare',
        exact: true,
        component: () => import('.//components/clienti/SospesiClientiDaRicontattare.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Sospesi Clienti' }],
        },
    },
    {
        path: '/statoassicurazioneclienti',
        name: 'statoassicurazioneclienti',
        exact: true,
        component: () => import('.//components/clienti/StatoAssicurazioneClienti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Stato Assicurazione' }],
        },
    },
    {
        path: '/vendite',
        name: 'vendite',
        exact: true,
        component: () => import('.//components/clienti/Vendite.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Vendite' }],
        },
    },
    {
        path: '/vendutodelgiorno',
        name: 'vendutodelgiorno',
        exact: true,
        component: () => import('.//components/clienti/VendutoDelGiorno.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Vendite' }],
        },
    },
    {
        path: '/vendutodelmese',
        name: 'vendutodelmese',
        exact: true,
        component: () => import('.//components/clienti/VendutoDelMese.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Vendite' }],
        },
    },
    /*COMPONENT BANCHE*/
    {
        path: '/flussibancari',
        name: 'flussibancari',
        exact: true,
        component: () => import('.//components/amministrazione/banche/flussibancari.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },
    {
        path: '/anticipobancheriba',
        name: 'anticipobancheriba',
        exact: true,
        component: () => import('.//components/amministrazione/banche/AnticipiBancheRiba.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },
    {
        path: '/saldibanche',
        name: 'saldibanche',
        exact: true,
        component: () => import('.//components/amministrazione/banche/saldibanche.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },

    {
        path: '/manutenzionebanche',
        name: 'manutenzionebanche',
        exact: true,
        component: () => import('.//components/amministrazione/banche/manutenzionebanche.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },

    {
        path: '/finanziamenti',
        name: 'finanziamenti',
        exact: true,
        component: () => import('.//components/amministrazione/finanziamenti/Finanziamenti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },
    {
        path: '/statoclienti',
        name: 'statoclienti',
        exact: true,
        component: () => import('.//components/amministrazione/StatoClienti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },
    {
        path: '/calcolodso',
        name: 'calcolodso',
        exact: true,
        component: () => import('.//components/amministrazione/CalcoloDso.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },
    {
        path: '/calcolocostodso',
        name: 'calcolocostodso',
        exact: true,
        component: () => import('.//components/amministrazione/CalcoloCostoDso.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },
    {
        path: '/scadenzarioajax',
        name: 'scadenzarioajax',
        exact: true,
        component: () => import('.//components/amministrazione/ScadenzarioAjax.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },
    {
        path: '/visitatorisbl',
        name: 'visitatorisbl',
        exact: true,
        component: () => import('.//components/clienti/VisitatoriSBL.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },

    {
        path: '/calcolocostodsoideale',
        name: 'calcolocostodsoideale',
        exact: true,
        component: () => import('.//components/amministrazione/CalcoloCostoDsoIdeale.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },
    {
        path: '/statofidiclienti',
        name: 'statofidiclienti',
        exact: true,
        component: () => import('.//components/amministrazione/StatoFidiClienti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },
    {
        path: '/budgetcashflow',
        name: 'budgetcashflow',
        exact: true,
        component: () => import('.//components/cashflow/BudgetCashFlow.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },

    {
        path: '/costiprevisti',
        name: 'costiprevisti',
        exact: true,
        component: () => import('.//components/cashflow/CostiPrevisti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },
    {
        path: '/scadenzeapertefornitore',
        name: 'scadenzeapertefornitore',
        exact: true,
        component: () => import('.//components/cashflow/ScadenzarioFornitori.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },
    {
        path: '/scadenzefinemesefornitore',
        name: 'scadenzefinemesefornitore',
        exact: true,
        component: () => import('.//components/cashflow/ScadenzeFineMese.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },

    {
        path: '/ordiniaperticlientiperdocumentocashflow',
        name: 'ordiniaperticlientiperdocumentocashflow',
        exact: true,
        component: () => import('.//components/cashflow/dialogDettaglioCashFlow/dialogOrdiniInevasiClientiPerDocCashflow.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },
    {
        path: '/dialogcommenti',
        name: 'dialogcommenti',
        exact: true,
        component: () => import('.//components/cashflow/dialogCommenti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },
    {
        path: '/pdfscadenzeraggruppato',
        name: 'pdfscadenzeraggruppato',
        exact: true,
        component: () => import('.//components/cashflow/PDFScadenzeRaggruppato.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },
    {
        path: '/autorizzafattureacquisti',
        name: 'autorizzafattureacquisti',
        exact: true,
        component: () => import('.//components/cashflow/AutorizzaFattureAcquisti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },

    {
        path: '/autorizzaordiniacquisti',
        name: 'autorizzaordiniacquisti',
        exact: true,
        component: () => import('.//components/cashflow/AutorizzaOrdiniAcquisti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },
    {
        path: '/budgetcashflow2',
        name: 'budgetcashflow2',
        exact: true,
        component: () => import('.//components/cashflow/BudgetCashFlowV2.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },
    {
        path: '/centrodicosto',
        name: 'centrodicosco',
        exact: true,
        component: () => import('.//components/cashflow/CentroDiCosto.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },

    {
        path: '/calcoloiva',
        name: 'calcoloiva',
        exact: true,
        component: () => import('.//components/cashflow/CalcoloIva.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },
    {
        path: '/dialogdettagliocashflowuscite',
        name: 'dialogdettagliocashflowuscite',
        exact: true,
        component: () => import('./components/cashflow/dialogDettaglioCashFlowUscite.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },


    {
        path: '/scadenzario',
        name: 'scadenzario',
        exact: true,
        component: () => import('.//components/cashflow//scadenzario/Scadenzario.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },
    {
        path: '/statoordinifornitoriconsegne',
        name: 'statoordinifornitoriconsegne',
        exact: true,
        component: () => import('.//components/cashflow/StatoOrdini.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },
    {
        path: '/caricacontratti',
        name: 'caricacontratti',
        exact: true,
        component: () => import('.//components/anagrafiche/CaricaContratti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },
    {
        path: '/manutenzionefinanziamenti',
        name: 'manutenzionefinanziamenti',
        exact: true,
        component: () => import('.//components/amministrazione/finanziamenti/ManutenzioneFinanziamenti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Banche', label: 'Banche' }],
        },
    },

    /*COMPONENT PROMOZIONI*/
    {
        path: '/andamentopromozioni',
        name: 'andamentopromozioni',
        exact: true,
        component: () => import('.//components/promozioni/AndamentoPromozioni.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Ordini Inevasi Cliente' }],
        },
    },
    {
        path: '/listiniribassati',
        name: 'listiniribassati',
        exact: true,
        component: () => import('.//components/promozioni/ListiniRibassati.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Ordini Inevasi Cliente' }],
        },
    },
    {
        path: '/anagraficheclienti',
        name: 'anagraficheclienti',
        exact: true,
        component: () => import('.//components/clienti/AnagraficheClienti.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Ordini Inevasi Cliente' }],
            requiresAuth: true
        },
    },
    {
        path: '/budgetagentimese',
        name: 'budgetagentimese',
        exact: true,
        component: () => import('.//components/agenti/BudgetAgentiMese.vue'),
        meta: {
            breadcrumb: [{ parent: 'Agenti', label: 'Budget Agenti' }],
            requiresAuth: true
        },
    },
    {
        path: '/budgetsquadramese',
        name: 'budgetsquadramese',
        exact: true,
        component: () => import('.//components/agenti/BudgetSquadraMese.vue'),
        meta: {
            breadcrumb: [{ parent: 'Agenti', label: 'Budget Squadra' }],
            requiresAuth: true
        },
    },
    {
        path: '/budgetagentitrimestre',
        name: 'budgetagentitrimestre',
        exact: true,
        component: () => import('.//components/agenti/BudgetAgentiTrimestre.vue'),
        meta: {
            breadcrumb: [{ parent: 'Agenti', label: 'Budget Agenti' }],
            requiresAuth: true
        },
    },
    {
        path: '/budgetsquadratrimestre',
        name: 'budgetsquadratrimestre',
        exact: true,
        component: () => import('.//components/agenti/BudgetSquadraTrimestre.vue'),
        meta: {
            breadcrumb: [{ parent: 'Agenti', label: 'Budget Squadra' }],
            requiresAuth: true
        },
    },
    {
        path: '/budgetstatotrimestre',
        name: 'budgetstatotrimestre',
        exact: true,
        component: () => import('.//components/agenti/BudgetStatoTrimestre.vue'),
        meta: {
            breadcrumb: [{ parent: 'Agenti', label: 'Budget Stato Trimestre' }],
            requiresAuth: true
        },
    },
    {
        path: '/budgettrimestregraph',
        name: 'budgettrimestregraph',
        exact: true,
        component: () => import('.//components/agenti/BudgetTrimestreGraph.vue'),

    },
    {
        path: '/budgetstatomensile',
        name: 'budgetstatomensile',
        exact: true,
        component: () => import('.//components/agenti/BudgetStatoMensile.vue'),
        meta: {
            breadcrumb: [{ parent: 'Agenti', label: 'Budget Stato Mensile' }],
            requiresAuth: true
        },
    },
    {
        path: '/budgetmesegraph',
        name: 'budgetmesegraph',
        exact: true,
        component: () => import('.//components/agenti/BudgetMeseGraph.vue'),

    },

    {
        path: '/costi',
        name: 'costi',
        exact: true,
        component: () => import('.//components/costi/index.vue'),
    },
    {
        path: '/manutenzioneCosti',
        name: 'manutenzioneCosti',
        exact: true,
        component: () => import('.//components/costi/componentes/crudTable.vue'),
        meta: {
            breadcrumb: [{ parent: 'Costi', label: 'Manutenzione Costi' }],
        },
    },
    {
        path: '/costimartina',
        name: 'costimartina',
        exact: true,
        component: () => import('.//components/costi/sedes/martina.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Ordini Inevasi Cliente' }],
            requiresAuth: true
        },
    },
    {
        path: '/costimodugno',
        name: 'costimodugno',
        exact: true,
        component: () => import('.//components/costi/sedes/modugno.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Ordini Inevasi Cliente' }],
            requiresAuth: true
        },
    },
    {
        path: '/costilecce',
        name: 'costilecce',
        exact: true,
        component: () => import('.//components/costi/sedes/lecce.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Ordini Inevasi Cliente' }],
            requiresAuth: true
        },
    },
    {
        path: '/costioria',
        name: 'costioria',
        exact: true,
        component: () => import('.//components/costi/sedes/oria.vue'),
        meta: {
            breadcrumb: [{ parent: 'Clienti', label: 'Ordini Inevasi Cliente' }],
            requiresAuth: true
        },
    },
    {
        path: '/update-chat-status',
        name: 'update-chat-status',
        exact: true,
        component: () => import('.//pages/UpdateChatStatus.vue'),
        meta: {
            breadcrumb: [{ parent: 'Teams', label: 'Cambio status messaggio chat' }],
            requiresAuth: true
        },
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    }
});

router.beforeEach((to, from, next) => {
    console.log('to', to);
    console.log('from', from);
    if (to.name == "login") {
        if (localStorage.getItem('token')) {
            next(from.path);
        } else {
            next();
        }
    } else {
        console.log('pagina diversa da login')
        const token = localStorage.getItem('token');
        if (token) {
            let decodedToken;
            try {
                decodedToken = VueJwtDecode.decode(token);
            } catch (error) {
                console.log('token scaduto');
                next('/login');
            }
            console.log('decodedToken', decodedToken);
            console.log('to', to);
            const role = decodedToken.ruolo;
            const routePermissions = routePermissionsMap[to.matched[0].path]
            if (!routePermissions) {
                next();
                return;
            }
            routePermissions.some(r => r == role) ? next() : next('/unauthorized');
        } else {
            console.log('non hai il token');
            next('/login');
        }
    }

})

export default router;